import React, { Component } from "react";
import { trackPromise } from 'react-promise-tracker';
import PopupWindow from "../PopupWindow/PopupWindow"
import SongList from "../SongList/SongList";
import './Home.css'
import logo from '../../assets/mix.png'

export const authEndpoint = 'https://accounts.spotify.com/authorize';
const clientId = "daf153b426b64077a4f6c083fd1f710e";
const redirectUri = "https://buildmeaplaylist.com/";
// const redirectUri = "http://localhost:3000"
const scopes = [
  "playlist-modify-public",
];

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tracks: [],
      recommendations: [],
      token: this.props.location.token,
      inprogress: false,
      exportButtonClass: "CTA",
      exportButtonText: "Export to Spotify",
      exportButtonEnabled: true,
      showToast: false,
      toastMessage: '',
      inputMessage: "Mix is your partner for building playlists. Enter a track above to start receiving recommendations!",
      errorMessage: false
    };
   
    this.addItem = this.addItem.bind(this);
    this.makePlaylist = this.makePlaylist.bind(this);
    this.replaceTrack = this.removeTrack.bind(this);
    this.replaceRec = this.removeRec.bind(this);
    this.export = this.export.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    fetch('https://mixwebapp.herokuapp.com/get_song', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify("testing"),
    });  
  }

  updateRecommendationsHeight = () => {
    const seedTracks = this.state.tracks.length; // Corrected line
    const recommendationsList = document.querySelector('.recommendations');
  
    if (recommendationsList) {
      // Start with 42.5% of the viewport height
      let recommendationsHeight = window.innerHeight * 0.63;
      recommendationsHeight = recommendationsHeight - 28 * seedTracks;
  
      // Ensure the height doesn't go below a certain minimum if needed
      const minHeight = 100; // Set this to whatever minimum you prefer
      recommendationsHeight = Math.max(recommendationsHeight, minHeight);
  
      recommendationsList.style.height = `${recommendationsHeight}px`;
    }
  };  

  getSong = async (songName) => {
    const response = await fetch('https://mixwebapp.herokuapp.com/get_song', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(songName)
    });
    return response.json();
  }

  // Method to add a song
  addItem = async (event) => {
    event.preventDefault();
  
    // Check if the seed tracks limit has been reached or if the process is in progress
    const { tracks, inprogress } = this.state;
    if (tracks.length >= 5 || inprogress) {
      return;
    }
  
    this.setState({ inprogress: true });
  
    // Fetch the song details from the backend
    const songDetails = await this.getSong(this._inputElement.value);
    if (songDetails) {
      const newTrack = {
        trackname: songDetails.trackname,
        artists: songDetails.artists,
        trackid: songDetails.trackid,
        popularity: songDetails.popularity,
        key: songDetails.trackid // Assuming trackid is unique
      };

      // Add the new track to the seed tracks and clear recommendations
      this.setState(prevState => ({
        tracks: prevState.tracks.concat(newTrack),
        recommendations: [],
        inprogress: false,
        exportButtonClass: "CTA",
        exportButtonText: "Save playlist",
        exportButtonEnabled: true
      }), () => {
        this.makePlaylist(); // Call makePlaylist after the state update
      });
    } else {
      // Handle the case where the song is not found
      this.setState({ inprogress: false, inputMessage: "Don't know that one... this is only built on tracks from December 2023 or earlier", errorMessage: true });
      setTimeout(() => {
        this.setState({ inputMessage: "Mix is your partner for building playlists. Enter a track above to start receiving recommendations!", errorMessage: false });
      }, 3000);
    }
  
    // Clear the input field
    this._inputElement.value = "";
    this.updateRecommendationsHeight();
  };
  

  makePlaylist() {
    this.setState({ inprogress: true });

    trackPromise(
      fetch('https://mixwebapp.herokuapp.com/make_playlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state.tracks),
      })
      .then((response) => response.json())
      .then((data) => {
        if (data['result'].length === 0) {
          // If no songs are returned, remove the most recent song and show a message
          this.setState({ inputMessage: "Don't know that one... this is only built on tracks from December 2023 or earlier", errorMessage: true });
          setTimeout(() => {
            this.setState({ inputMessage: "Mix is your partner for building playlists. Enter a track above to start receiving recommendations!", errorMessage: false });
          }, 3000);
          this.setState(prevState => {
            const newTracks = prevState.tracks.slice(0, -1); // Remove the last track
            return {
              tracks: newTracks,
              recommendations: [],
              inprogress: false
            };
          });
          this.updateRecommendationsHeight();
        } else {
          // Existing logic for handling recommendations
          const newRecommendations = data['result'].map(track => ({
            trackname: track.trackname,
            artists: track.artists,
            trackid: track.trackid,
            popularity: track.popularity,
            key: track.trackid // Use trackid as the key for React list rendering
          }));

          this.setState({
            recommendations: [...this.state.recommendations, ...newRecommendations],
            inprogress: false,
            exportButtonClass: "CTA",
            exportButtonText: "Save playlist",
            exportButtonEnabled: true
          });
        }
      })
      .catch(error => {
        console.error("Error making playlist:", error);
        this.setState({ inprogress: false });
      })
    );
  }

  onFailure = (error) => {
    this.props.onFailure(error);
  }

  removeTrack(id) {
    this.setState(prevState => ({
        tracks: prevState.tracks.filter(el => el.key !== id),
        recommendations: [],
        exportButtonClass: "CTA",
        exportButtonText: "Save playlist",
        exportButtonEnabled: true
      }), () => {
      if (this.state.tracks.length > 0) {
        this.makePlaylist()
      }}
    );

    this.updateRecommendationsHeight();
  }; 

  removeRec(id) {
    this.setState(prevState => ({
        recommendations: prevState.recommendations.filter(el => el.key !== id),
    }));
  }

  getTokenFromCookie() {
    const name = "spotifyToken=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  

  export = async () => {
    if (this.state.tracks.length === 0) {
      // Show toast message
      this.setState({ showToast: true, toastMessage: "No recommendations yet. Add a track to get started!" });
  
      setTimeout(() => {
          this.setState({ showToast: false });
      }, 3000); // 3000 milliseconds = 3 seconds
  
      return;
    }
  
    try {
      let accessToken = this.getTokenFromCookie();
  
      if (!accessToken) {
        // Token does not exist or expired, initiate authentication
        const popup = PopupWindow.open(
          'spotify-authorization',
          `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}&response_type=token&show_dialog=true`,
          { width: window.innerWidth, height: window.innerHeight }
        );
  
        // Wait for the popup to close, then check the location hash for the token
        popup.promise.then(data => {
          accessToken = data.access_token;
  
          // Set the cookie with the token
          const d = new Date();
          d.setTime(d.getTime() + (60*60*1000));
          const expires = "expires="+ d.toUTCString();
          document.cookie = `spotifyToken=${accessToken};${expires};path=/`;
  
          // Call the exportPlaylist function after the popup is closed and the token is obtained
          this.exportPlaylist(accessToken);
        });
      } else {
        // If the token already exists, proceed with exporting the playlist
        this.exportPlaylist(accessToken);
      }
    } catch (error) {
      console.error("Network or other error:", error);
    }
  };
  
  exportPlaylist = async (accessToken) => {
    // Now proceed with exporting the playlist using the token
    const response = await fetch('https://mixwebapp.herokuapp.com/export', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({'tracks': this.state.recommendations.slice(0, 40), 'token': accessToken}),
    });
  
    if (response.ok) {
      this.setState({
        exportButtonClass: "CTA_completed",
        exportButtonText: "Saved",
        exportButtonEnabled: false
      });
      localStorage.setItem('exportCompleted', true);
    } else {
      // Handle errors
    }
  };

  handleInputChange(event) {
    if (event.target.value) {
      this.setState({ inputMessage: "Hit 'return' or 'enter' on your keyboard to submit the track", errorMessage: false });
    } else {
      this.setState({ inputMessage: "Mix is your partner for building playlists. Enter a track above to start receiving recommendations!", errorMessage: false });
    }
  }

  render() {
    return (
      <div className="App">
        <head>
          <meta name="theme-color" content="#FAFAFA"></meta>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no"/>
        </head>
        <img className="Logo" src={logo} alt="Mix Logo" />
        <div className="input-container">
          <form onSubmit={this.addItem}>
            <input 
              type="text" 
              id="myInput" 
              placeholder="Somebody to Love - Queen"
              ref={(a) => this._inputElement = a} 
              name="songname"
              onChange={this.handleInputChange}
              disabled={this.state.tracks.length >= 5} 
            />
            <label htmlFor="myInput">Track</label>
          </form>
        </div>

        {this.state.inprogress && (
          <div className="loading-dots">
            <div className="loading-dot" style={{ color: 'black' }}></div>
            <div className="loading-dot" style={{ color: 'gray' }}></div>
            <div className="loading-dot" style={{ color: 'lightgray' }}></div>
          </div>
        )}

        {this.state.tracks.length > 0 ? (
          <div>
            <div> <SongList entries={this.state.tracks} style={'seedsongs'} _handleDelete={this.removeTrack.bind(this)}/> </div>
            <div className="line"></div>
            <div> <SongList entries={this.state.recommendations.slice(0,40)} style={'recommendations'} _handleDelete={this.removeRec.bind(this)}/> </div>
          </div>
        ) : (
          <div className={`Tagline ${this.state.errorMessage ? 'error' : ''}`}>
            {this.state.inputMessage}
          </div>
        )}

        <button type="button" className={this.state.exportButtonClass} onClick={this.export}>
          Save playlist
        </button>

      </div>
    );
  }
}  

export default Home;